* {
  box-sizing: border-box;
}

body {
  overflow: hidden;
  position: fixed;
  width: 100%;
}

.App {
  height: 100vh;
  padding: 0 20px;

  background: linear-gradient(
    to left top,
    rgb(255, 206, 98, 1) /*bottom-right color*/,
    rgba(255, 209, 225, 1) 100% /*top-left color*/
  );

  display: flex;
  align-items: center;
  justify-content: center;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
}

.App__content {
  margin-top: -15%;
}

.App__wrapper {
  position: relative;
  height: 107px;
  width: 350px;
  margin: 0 auto;
}

.Notification {
  width: 100%;
  margin: 0 auto;
  padding: 10px;
  height: 100%;
  background: rgba(255, 255, 255, 0.85);
  backdrop-filter: blur(30px);
  border-radius: 12px;
  position: absolute;
  top: 0;
  left: 0;
}

.Notification__header {
  display: flex;
  align-items: center;
}

.Notification__image {
  width: 20px;
  height: 20px;
  margin-right: 8px;
  border-radius: 2px;
}
.Notification__title {
  font-weight: 400;
  letter-spacing: -0.31px;
  font-size: 13px;
  text-transform: uppercase;
}

.Notification__timestamp {
  font-weight: 400;
  letter-spacing: -0.31px;
  font-size: 13px;
  margin-left: auto;
}
.Notification__body {
  margin-top: 8px;
}
.Notification__body-text {
  font-weight: 400;
  font-size: 15px;
  letter-spacing: -0.24px;
  line-height: 20px;
  color: #000000;
  margin: 0;
}

.App_title {
  font-size: 25px;
  text-align: center;
  margin: 20px 0;
  font-weight: 400;
}

.Download {
  margin-top: 20px;
  position: relative;
  z-index: 99999;
  display: flex;
  justify-content: center;
}

.email {
  color: black;
  font-weight: 600;
}

.Download__button {
  display: block;
  width: auto;
  height: 50px;
  margin: 0 auto;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.Rating {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3px;
}

.Rating__star {
  width: 13px;
  height: auto;
}

.Rating__disclaimer {
  font-size: 12px;
  line-height: 12px;
  margin: 0;
  margin-left: 10px;
}